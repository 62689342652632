import React, { useState, useEffect } from "react";
import "./offer.css";
import wukong from "../../images/wukong.png";
import zues from "../../images/zues.png";

const LimitedTimeOfferBox = () => {
  const calculateTimeLeft = () => {
    const endDate = new Date("2024-10-08T23:59:59").getTime(); 
    const now = new Date().getTime();
    const difference = endDate - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const handleLeadTracking = () => {
    if (typeof window.fbq !== 'undefined') {
      // Facebook Pixel Lead tracking event
      window.fbq('track', 'Lead');
      console.log('Lead event tracked');
    } else {
      console.error('Facebook Pixel not initialized');
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="content-container">
      <img className="side-image" src={wukong} alt="left" />

      <div className="main-content">
        {/* Countdown Timer */}
        <div className="countdown">
          <p className="timer-title">
            <span className="old-bonus">150%</span>{" "}
            <span className="new-bonus">200% Bonus Now!</span>
          </p>
          <div className="time">
            <span className="time-number">{timeLeft.days}</span>
            <p className="time-label">Days</p>
            <span className="time-number">{timeLeft.hours}</span>
            <p className="time-label">Hours</p>
            <span className="time-number">{timeLeft.minutes}</span>
            <p className="time-label">Minutes</p>
            <span className="time-number">{timeLeft.seconds}</span>
            <p className="time-label">Seconds</p>
          </div>
        </div>

        {/* Pricing Card */}
        <div className="pricing-box">
          <a href="https://tinyurl.com/3ps2acyr" target="_blank" rel="noreferrer">
            <button className="signup-button" onClick={handleLeadTracking}>Register Now!</button>
          </a>

          <ul className="features-list">
            <li>Limited Time Offer</li>
            <li>Cuci Dlm 3 Minutes</li>
            <li>Multiple Bonus</li>
            <li>Slots</li>
            <li>Casino</li>
            <li>Sports Betting</li>
            <li>Arcade</li>
            <li>4D Lottery</li>
          </ul>
        </div>
      </div>

      <img className="side-image" src={zues} alt="right" />
    </div>
  );
};

export default LimitedTimeOfferBox;
